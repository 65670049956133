import './App.css';
import { Route, Routes } from 'react-router-dom';
import Home from './pages/home';
import Catagory from './pages/category';
import BaseWrapper from './wrappers/BaseWrapper';
import Post from './pages/post';
import { BASE_URL_CATEGORY, BASE_URL_POST } from './constants';


function App() {
  return (
    <div className="App">
      <Routes>
        <Route path='/' element={
          <BaseWrapper>
            <Home />
          </BaseWrapper>
        } />
        <Route path={`${BASE_URL_POST}:slug`} element={
          <BaseWrapper>
            <Post />
          </BaseWrapper>
        } />
        <Route path={`${BASE_URL_CATEGORY}:slug`} element={
          <BaseWrapper>
            <Catagory />
          </BaseWrapper>
        } />
      </Routes>
    </div>
  );
}

export default App;
