import { find } from "lodash";
import React from "react"
import { useParams } from "react-router-dom"
import allPosts from "../posts";
import "./post.css";

const Post = () => {
  const { slug } = useParams();
  const singlePost = find(allPosts, (post) => post.slug === slug);
  const { title, body } = singlePost;
  return (
    <div className="postWrapper">
      <h2>{title}</h2>
      {body}
    </div>
  )
}

export default Post