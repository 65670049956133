import { filter, map } from "lodash";
import React from "react"
import { useParams } from "react-router-dom"
import Thumbnail from "../components/thumbnail";
// import { CATEGORIES } from "../constants";
import allPosts from "../posts";

const Catagory = () => {
  const { slug } = useParams();
  const filteredPosts = filter(allPosts, ({ category }) => category.SLUG === slug);
  return (
    <div className="tileWrapper">
      {map(filteredPosts, post => <Thumbnail {...{ post }} />)}
    </div>
  )
}

export default Catagory