import React from "react"
import { Link, useLocation, useParams } from "react-router-dom"
import { map, uniq } from "lodash"
import allPosts from "../posts"
import './navbar.css'
import { BASE_URL_CATEGORY } from "../constants"

const Navbar = () => {

  const { slug } = useParams();
  const { pathname } = useLocation();

  const allCategories = map(allPosts, ({ category }) => category);
  const uniqeCategories = uniq(allCategories);

  const isCategory = pathname.includes(BASE_URL_CATEGORY);

  return (
    <div className="blog_nav">
      {map(uniqeCategories, category =>
        <div className={`nav_item 
        ${isCategory && category.SLUG === slug
            ? 'active'
            : null}
          `}>
          <Link to={`${BASE_URL_CATEGORY}${category.SLUG}`}>
            {category.TITLE}
          </Link>
        </div>
      )}
    </div>
  )
}

export default Navbar