import { CATEGORIES } from "../../constants";
import coverImage from './cover.png';

const PostBody = () => {
  return (
    <div>
      <img className="coverImg" alt="logos of Lets Encrypt and Lighsail" src={coverImage} />

      <h2>Overview</h2>
      <ul>
        <li>Let's Encrypt is Free</li>
        <li>Let's Encrypt certs expire every 90 days</li>
        <li>You can auto renew, but this guide will show you *how to manually renew.*</li>
      </ul>

      <h2>Prerequisites</h2>

      <p>This guide assumes you have installed a Wordpress instance on AWS Lightsail using <a href="https://lightsail.aws.amazon.com/ls/docs/en_us/articles/amazon-lightsail-using-lets-encrypt-certificates-with-wordpress#request-a-lets-encrypt-certificate-wordpress">this guide or similar</a></p>

      <p>There seems to be a bit of missing info in the above link, as the tutorial is mostly for creating a new certificate instead of renewing.</p>

      <h2>Step 1: Follow AWS's Guide, Steps 3-6</h2>

      So AWS's renewal guide isn't specific enough. It says to follow Step 3 to renew, but fails to say continue on through Step 6. To be clear, here are the steps you should complete to renew:

      <ul>
        <li>"Step 3: Request a Let’s Encrypt SSL wildcard certificate"</li>
        <li>"Step 4: Add TXT records to your domain’s DNS zone in Lightsail"</li>
        <li>"Step 5: Confirm that the TXT records have propagated"</li>
        <li>"Step 6: Step 6: Complete the Let’s Encrypt SSL certificate request"</li>
      </ul>

      <h2>The Missing Last Part</h2>

      <p>After completing Step 6, you need to _restart_ apache. This is not the same as stopping and doing all those directory mappings in AWS's Step 7. Remember you have already mapped the cert, it didn't change location, you just replaced it with a new one.</p>

      <p>To restart run <code>sudo /opt/bitnami/ctlscript.sh restart apache</code></p>

      <h2>Check Your Work</h2>

      <p>After the restart in the browser-based console, go to your website and verify it is up.</p>

      <h3>References</h3>

      <ul>
        <li><a href="https://lightsail.aws.amazon.com/ls/docs/en_us/articles/amazon-lightsail-using-lets-encrypt-certificates-with-wordpress#request-a-lets-encrypt-certificate-wordpress">Tutorial: Using Let’s Encrypt SSL certificates with your WordPress instance in Amazon Lightsail</a></li>
        <li><a href="https://metablogue.com/enable-lets-encrypt-ssl-aws-lightsail/#link-let%e2%80%99s-encrypt-ssl-certificate-to-apache">How To Enable Let’s Encrypt SSL Certificate on AWS LightSail Server</a></li>
      </ul>
    </div>
  )
}

const postDetails = {
  title: "Renew Let's Encrypt SSL + Lightsail + WP",
  slug: 'renew-ssl-lightsail',
  date: 'Jan 1, 2022',
  category: CATEGORIES.HOW_TO,
  coverAlt: 'None', // Description of the image
  coverTitle: 'None', // Tooltip for users not using assistive technologies
  cover: coverImage,
  body: <PostBody />,
}

export default postDetails;