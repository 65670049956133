import React from "react"

import "./footer.css"

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <div className="footer">
      <div className="leftCol">
        <span>© Mike Cooknick {year}</span>
      </div>
      <div className="rightCol">
        <span>
          <a href="https://twitter.com/mikecooknick">@mikecooknick</a> / business@cooknick.com</span>
      </div>
    </div>
  )
}


export default Footer