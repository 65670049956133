import React from "react"

import { Link } from "react-router-dom"

import "./thumbnail.css"
import { BASE_URL_POST } from "../constants";

const BlankImg = () => (<div className="thumbImage">
  <span>No Image Available</span>
</div>);

const Thumbnail = ({ post }) => {
  const { title, slug, date, cover, coverAlt, coverTitle } = post;

  return (
    <Link to={`${BASE_URL_POST}${slug}`} className="thumbnail">
      <div className="content">
        <div className="imgContainer">
          {cover
            ? <div
              role="img"
              className="coverImg"
              aria-label={coverAlt}
              title={coverTitle}
              style={{ backgroundImage: `url(${cover})` }} />
            : <BlankImg {...{ cover }} />}
        </div>
        <div className="titleContainer">
          <h2 className="postTitle">{title}</h2>
          <p className="postDate">{date}</p>
        </div>
      </div>
    </Link>
  )

}

export default Thumbnail
