import React from "react"
import { map } from 'lodash';
import allPosts from "../posts";
import Thumbnail from "../components/thumbnail";

const Home = () => {
  // add filter for unique post slugs
  return (
    <div>
      <div className="tileWrapper">
        {map(allPosts, post => <Thumbnail {...{ post }} />)}
      </div>
    </div>
  )
}

export default Home