export const BASE_URL_POST = '/post/';
export const BASE_URL_CATEGORY = '/category/';

export const CATEGORIES = {
  JOURNAL: {
    SLUG: 'journal',
    TITLE: 'Journal',
  },
  HOW_TO: {
    SLUG: 'how-to',
    TITLE: 'How To',
  },
  VLOG: {
    SLUG: 'vlog',
    TITLE: 'Vlog',
  },
  ABOUT: {
    SLUG: 'about',
    TITLE: 'About',
  },
}